/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Tooltip, TextField, ClickAwayListener } from '@mui/material'
import { Check, Clear } from '@mui/icons-material'
import { endOfYear, format, isThisYear, startOfYear } from 'date-fns'
import Loading from '../_library/Loading'
import MUIDataTable from 'mui-datatables'
import DeleteButton from '../_library/DeleteButton'
import { getFlightPrice } from '../../_helpers/dataHelper'
import { ACCOUNT } from '../../_constants/routes'
import { pick, pickBy } from 'lodash'
import { AIRCRAFT_COLLECTION, BILLING_TYPE_COLLECTION, FLIGHT_COLLECTION, FLIGHT_TYPE_COLLECTION, ROLE_INSTRUCTOR, USER_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers'
import { useFirestore } from '../../hooks/useFirestore'
import useAuth from '../../hooks/useAuth'
import { selectYear } from '../../store/actions/dataActions'


const STORAGE_KEY = 'allFlightsTableState'
const AllFlightsTable = () => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const flightHooks = useFirestore(FLIGHT_COLLECTION, { storeAs: 'AllFlightsTable' })
  const dbFlights = flightHooks.getDocs()
  const profile = useAuth().getProfile()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const flightTypeHooks = useFirestore(FLIGHT_TYPE_COLLECTION)
  const dbFlightTypes = flightTypeHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const billingTypeHooks = useFirestore(BILLING_TYPE_COLLECTION)
  const dbBillingTypes = billingTypeHooks.getDocs()
  const selectedYear = useSelector(state => state.data.selectedYear)
  
  const [selectedCell, selectCell] = useState(null)
  
  useEffect(() => {
    if (selectedYear) {
      flightHooks.listen(pickBy({
        orderBy: [['startDate', 'asc']],
        startAt: selectedYear !== 'all' && startOfYear((new Date()).setFullYear(selectedYear) || new Date()),
        endAt: selectedYear !== 'all' && endOfYear((new Date()).setFullYear(selectedYear) || new Date()),
      }))
      return flightHooks.unsubscribe()
    }
  }, [selectedYear])
  
  useEffect(() => {
    if (!selectedYear) dispatch(selectYear((new Date()).getFullYear()))
  }, [selectedYear])
  
  const data = useMemo(() => dbFlights?.map(val => {
    if (!val.flightTypeRef) console.log(val)
    return [
      val,
      val.id,
      val.startDate,
      { id: val.aircraftRef.id, ...val.aircraft },
      { id: val.pilotRef?.id, ...val.pilot },
      val.instructor ? { id: val.instructorRef?.id, ...val.instructor } : null,
      { id: val.flightTypeRef.id, ...val.flightType },
      val.departureICAO ? 'writing' : val.departure,
      val.arrivalICAO ? 'writing' : val.arrival,
      val.landings,
      val.counterDeparture,
      val.counterArrival,
      val.duration,
      val.fuelBefore,
      val.fuelAfter,
      val.gPos,
      val.gNeg,
      val.billingTypeId ? 'writing' : { id: val.billingTypeRef?.id, ...val.billingType },
      JSON.stringify({
        formula: val.billingType?.priceFormula,
        aircraftFix: val.aircraft?.price?.fix,
        aircraftHour: val.aircraft?.price?.hour,
        aircraftFuelFreeHour: val.aircraft?.price?.fuelFreeHour,
        landingCost: val.arrival?.landingTax,
        duration: val.duration,
        landings: val.landings,
        total: Number(getFlightPrice(val)),
      }),
      val.comments || '',
      val.validated ? 'oui' : 'non',
      { id: val.id, validated: val.validated },
    ]
  }), [dbFlights])
  
  const instructors = useMemo(() => dbUsers?.filter(u => u.roles?.includes(ROLE_INSTRUCTOR)), [dbUsers])
  
  const update = (key, val) =>
    flightHooks.updateDoc(dbFlights[selectedCell.dataIndex].id, { [key]: val }, true)
      .then(() => selectCell(null))
  
  const localState = window.localStorage.getItem(STORAGE_KEY) &&
    JSON.parse(window.localStorage.getItem(STORAGE_KEY))
  
  if (!dbFlights) return <Loading />
  return (
    <MUIDataTable
      data={data}
      sx={{
        '& .MuiTableCell-body': {
          fontSize: '0.8rem',
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
        '& .MuiTableCell-head': {
          fontSize: '0.8rem',
        },
      }}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        { name: 'ID', options: { filter: false, sort: false, display: localState?.columns[1].display || 'false' } },
        {
          name: 'startDate',
          label: 'Date',
          options: {
            display: localState?.columns[2].display || 'true',
            filter: true,
            filterList: localState?.filterList[2] || [],
            sortThirdClickReset: true,
            sortDescFirst: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <DatePicker
                    open
                    value={val}
                    onChange={value => update('startDate', value)}
                    minDate={startOfYear(new Date())}
                    maxDate={endOfYear(new Date())}
                    disabled={!isThisYear(val)}
                  />
                </ClickAwayListener>
                : format(val, 'dd-MM-yyyy HH:mm'),
          },
        },
        {
          name: 'aircraft',
          label: 'Avion',
          options: {
            display: localState?.columns[3].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[3] || [],
            sortThirdClickReset: true,
            sortCompare: order => (a, b) => a.data.name.localeCompare(b.data.name) * (order === 'asc' ? 1 : -1),
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('aircraftRef', aircraftHooks.getDocRef(e.target.value))} defaultValue={val?.id}>
                    {dbAircrafts?.map(a => <option value={a.id} key={a.id}>{a.name}</option>)}
                  </select>
                </ClickAwayListener>
                : val?.name,
          },
        },
        {
          name: 'pilot',
          label: 'Pilote',
          options: {
            display: localState?.columns[4].display || 'true',
            filter: true,
            filterList: localState?.filterList[4] || [],
            sortThirdClickReset: true,
            sortCompare: order => (a, b) => {
              const aName = a.data.firstname + ' ' + a.data.lastname
              const bName = b.data.firstname + ' ' + b.data.lastname
              return aName.localeCompare(bName) * (order === 'asc' ? 1 : -1)
            },
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('pilotRef', userHooks.getDocRef(e.target.value))} defaultValue={val?.id}>
                    {dbUsers?.map(u => <option value={u.id} key={u.id}>{u.firstname + ' ' + u.lastname}</option>)}
                  </select>
                </ClickAwayListener>
                : typeof val === 'object' ? val.firstname + ' ' + val.lastname : val,
          },
        },
        {
          name: 'instructor',
          label: 'Instructeur',
          options: {
            display: localState?.columns[5].display || 'true',
            filter: true,
            filterList: localState?.filterList[5] || [],
            sortThirdClickReset: true,
            sortCompare: order => (a, b) => {
              const aName = a.data.firstname + ' ' + a.data.lastname
              const bName = b.data.firstname + ' ' + b.data.lastname
              return aName.localeCompare(bName) * (order === 'asc' ? 1 : -1)
            },
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('instructorRef', e.target.value === 'null' ? null : userHooks.getDocRef(e.target.value))} defaultValue={val?.id}>
                    <option value='null'></option>
                    {instructors && instructors.map(u => <option value={u.id} key={u.id}>{u.firstname + ' ' + u.lastname}</option>)}
                  </select>
                </ClickAwayListener>
                : typeof val === 'object' && val !== null ? val.firstname + ' ' + val.lastname : val || '',
          },
        },
        {
          name: 'flightType',
          label: 'Type de vol',
          options: {
            display: localState?.columns[6].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[6] || [],
            sortCompare: order => (a, b) => a.data.name.localeCompare(b.data.name) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('flightTypeRef', flightTypeHooks.getDocRef(e.target.value))} defaultValue={val.id}>
                    {dbFlightTypes?.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
                  </select>
                </ClickAwayListener>
                : typeof val === 'object' ? val.name : val,
          },
        },
        {
          name: 'departure',
          label: 'Départ',
          options: {
            display: localState?.columns[7].display || 'true',
            filter: true,
            filterList: localState?.filterList[7] || [],
            sortCompare: order => (a, b) => a.data?.ICAO.localeCompare(b.data?.ICAO) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val?.ICAO}
                    onKeyPress={e => e.key === 'Enter' && update('departureICAO', e.target.value)} />
                </ClickAwayListener>
                : typeof val === 'object' ? val.ICAO : val,
          },
        },
        {
          name: 'arrival',
          label: 'Arrivée',
          options: {
            display: localState?.columns[8].display || 'true',
            filter: true,
            filterList: localState?.filterList[8] || [],
            sortCompare: order => (a, b) => a.data?.ICAO.localeCompare(b.data?.ICAO) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val?.ICAO}
                    onKeyPress={e => e.key === 'Enter' && update('arrivalICAO', e.target.value)} />
                </ClickAwayListener>
                : typeof val === 'object' ? val.ICAO : val,
          },
        },
        {
          name: 'landings',
          label: 'Atterrissages',
          options: {
            display: localState?.columns[9].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('landings', e.target.value)}
                    type='number' />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'counterDeparture',
          label: 'Départ',
          options: {
            display: localState?.columns[10].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('counterDeparture', e.target.value)}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'counterArrival',
          label: 'Arrivée',
          options: {
            display: localState?.columns[11].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('counterArrival', e.target.value)}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'duration',
          label: 'Durée',
          options: {
            display: localState?.columns[12].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('duration', parseFloat(e.target.value))}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'fuelBefore',
          label: 'Fuel Départ',
          options: {
            display: localState?.columns[13].display || 'true',
            filter: false,
            sortCompare: order => (a, b) => ((a.data || 0) - (b.data || 0)) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('fuelBefore', parseFloat(e.target.value))}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'fuelAfter',
          label: 'Fuel Arrivée',
          options: {
            display: localState?.columns[14].display || 'true',
            filter: false,
            sortCompare: order => (a, b) => ((a.data || 0) - (b.data || 0)) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('fuelAfter', parseFloat(e.target.value))}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'gPos',
          label: 'G+',
          options: {
            display: localState?.columns[15].display || 'false',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('gPos', parseFloat(e.target.value))}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'gNeg',
          label: 'G-',
          options: {
            display: localState?.columns[16].display || 'false',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('gNeg', parseFloat(e.target.value))}
                    type='number'
                    inputProps={{ step: .01 }} />
                </ClickAwayListener>
                : val,
          },
        },
        {
          name: 'billingType',
          label: 'Facturation',
          options: {
            display: localState?.columns[17].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[17] || [],
            sortThirdClickReset: true,
            sortCompare: order => (a, b) => a.data.name.localeCompare(b.data.name) * (order === 'asc' ? 1 : -1),
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <select onChange={e => update('billingTypeRef', billingTypeHooks.getDocRef(e.target.value))} defaultValue={val.id}>
                    {dbBillingTypes?.map(u => <option value={u.id} key={u.id}>{u.name}</option>)}
                  </select>
                </ClickAwayListener>
                : typeof val === 'object' ? val.name : val,
          },
        },
        {
          name: 'price',
          label: 'Prix',
          options: {
            display: localState?.columns[18].display || 'true',
            filter: false,
            sort: false,
            customBodyRenderLite: dataIndex => // eslint-disable-line react/prop-types
              <Tooltip title={data[dataIndex][0].billingType?.priceFormula || ''}>
                <div onClick={() => navigate(`${ACCOUNT}/pilot`, { state: { entryId: data[dataIndex][0].accountEntryRef.id } })}>{getFlightPrice(data[dataIndex][0])}</div>
              </Tooltip>,
          },
        },
        {
          name: 'comments',
          label: 'Commentaires',
          options: {
            display: localState?.columns[19].display || 'true',
            filter: false,
            sortThirdClickReset: true,
            customBodyRender: (val, tableMeta) =>
              selectedCell && selectedCell.colIndex === tableMeta.columnIndex && selectedCell.flightId === tableMeta.rowData[0].id
                ? <ClickAwayListener onClickAway={() => selectCell(null)}>
                  <TextField
                    variant='standard'
                    defaultValue={val}
                    onKeyPress={e => e.key === 'Enter' && update('comments', e.target.value)}
                    multiline />
                </ClickAwayListener>
                : val || 'null',
          },
        },
        {
          name: 'validated',
          label: 'Validé',
          options: {
            display: localState?.columns[20].display || 'false',
            filterList: localState?.filterList[20] || [],
            sortThirdClickReset: true,
          },
        },
        {
          name: 'Actions',
          options: {
            download: false,
            filter: false,
            sort: false,
            viewColumns: false,
            // eslint-disable-next-line
            customBodyRender: ({ id, validated }) => <>
              <DeleteButton deleteAction={() => flightHooks.deleteDoc(id)} />
              {profile.roles.includes('admin') && // eslint-disable-line react/prop-types
                <Button onClick={() => flightHooks.updateDoc(id, { validated: !validated }, true)} title='valider le vol'>
                  {validated ? <Clear /> : <Check />}
                </Button>
              }
            </>,
          },
        },
      ]}
      options={{
        selectableRows: 'none',
        downloadOptions: {
          filename: `flights-${selectedYear}.csv`,
          separator: ';',
          filterOptions: {
            useDisplayedColumnsOnly: false,
            useDisplayedRowsOnly: true,
          },
        },
        onCellClick: (colData, tableMeta) => !selectedCell && selectCell({ ...tableMeta, flightId: dbFlights[tableMeta.dataIndex].id }),
        onTableChange: (action, tableState) => {
          if (action === 'propsUpdate') return
          window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
        },
        sortOrder: localState?.sortOrder || {},
        rowsPerPage: localState?.rowsPerPage || 10,
        jumpToPage: true,
        rowsPerPageOptions: [10, 50, 100],
      }}
    />
  )
}

export default AllFlightsTable
